import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "radar" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-bottom":"20px"}
}
const _hoisted_3 = {
  key: 1,
  style: {"margin-bottom":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart = _resolveComponent("chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.$slots.title)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_2, [
            _renderSlot(_ctx.$slots, "title")
          ]))
        : (_ctx.title)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
      _createVNode(_component_chart, {
        option: _ctx.chartOption,
        style: _normalizeStyle({ height: `${_ctx.chartHeight}` })
      }, null, 8, ["option", "style"])
    ])
  ]))
}